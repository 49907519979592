import * as React from "react";
import { Link, PageProps } from "gatsby";
import favicon from "../../images/favicon.ico";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./Footer";
import * as commonStyles from "./common.module.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>

      <Navbar pageType="inernal" />
      <main>
        <div className={"container "+commonStyles.internal} >{children}</div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
